@layer components {
    .prose {
        section:first-child {
            margin-top: 0 !important;
        }

        /* Custom headings */
        h1 {
            @apply font-serif text-3xl m-0;
        }

        h2,
        h3,
        h4 {
            font-weight: 500 !important;
        }

        h3,
        h4 {
            @apply m-0;
        }

        /* Custom anchor styles */
        .anchor {
            @apply absolute invisible no-underline;
            margin-left: -1em;
            padding-right: 0.5em;
            width: 80%;
            max-width: 700px;
            cursor: pointer;

            &:hover {
                @apply visible;
            }

            &:after {
                @apply text-neutral-300 dark:text-neutral-700;
                content: '#';
            }
        }

        /* Custom link styles */
        a {
            /* Scalable link */
            @apply inline-flex my-0 underline cursor-pointer duration-200 ease-in-out transform active:scale-[.98] transition-transform;

            @apply decoration-neutral-400 dark:decoration-neutral-600 hover:decoration-[#158f7a] hover:dark:decoration-[#2cffd1cb] hover:decoration-dotted underline-offset-2 decoration-[0.1em] scaleOnClick;
        }

        /* Hover effect to show anchor */
        *:hover > .anchor {
            @apply visible;
        }

        /* Custom code block styles */
        pre {
            @apply border border-neutral-900 bg-neutral-900;

            code {
                @apply text-neutral-800 dark:text-neutral-200 p-0;
                border: initial;
            }

            /* Scrollbar styles for code blocks */
            &::-webkit-scrollbar {
                display: none;
            }

            /* Other browser scrollbar styles */
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }

        /* Custom inline code styles */
        code {
            @apply text-neutral-800 dark:text-neutral-200 px-1 py-0.5 border border-neutral-100 dark:border-neutral-800 rounded-lg bg-neutral-100 dark:bg-neutral-900;
        }

        /* Custom image styles */
        img {
            @apply m-0;
        }

        /* First child margin override */
        > :first-child {
            margin-top: 1.25em !important;
            margin-bottom: 1.25em !important;
        }

        /* Custom styles for embedded tweets */
        .tweet a {
            @apply scaleOnClick;
            text-decoration: inherit;
            font-weight: inherit;
        }

        /* Custom styles for callouts */
        .callout > p {
            margin: 0 !important;
        }
    }

    /* Language-specific code styles */
    code[class*='language-'],
    pre[class*='language-'] {
        @apply text-neutral-50;
    }

    /* Table styles for overflow */
    table {
        display: block;
        max-width: fit-content;
        overflow-x: auto;
        white-space: nowrap;
    }

    /* Remove input shadow on Safari mobile */
    input[type='text'],
    input[type='email'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
