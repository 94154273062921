@import 'tailwind.css';
@import 'typography.css';
@import 'mask.css';
@import 'swap.css';
@import 'drawer.css';
@import 'button.css';

@layer base {
    :root {
        /* Your default theme */
        --clear: transparent;
        --border: #e5e5e5; /* neutral-200 */

        --selection: rgba(0, 253, 224, 0.105);
        --selection-foreground: var(--primary);

        --background: #fafafa;
        --background-monochrome: white;
        --foreground: black;

        --base: #e5e5e5; /* neutral-200 */
        --base-foreground: #525252; /* neutral-600 */

        --primary: #158f7a; /* old: #2cffd1cb */
        --primary-foreground: #2cffd1cb;

        --destructive: #ff3b30;
        --destructive-foreground: ;

        --error: #ff2c2c;
    }

    .dark,
    [data-theme='dark'] {
        --clear: transparent;
        --border: #262626; /* neutral-800 */

        --selection: rgba(0, 253, 224, 0.105);
        --selection-foreground: var(--primary);

        --background: #0a0a0a; /* neutral-950 */
        --background-monochrome: black;
        --foreground: white;

        --base: #171717; /* neutral-900 */
        --base-foreground: #a3a3a3; /* neutral-400 */

        --primary: #2cffd1cb;
        --primary-foreground: #2cffd1cb;

        --destructive: #ff453a;
        --destructive-foreground: ;

        --error: #ff2c2c;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    html {
        @apply antialiased tracking-wide bg-background;

        min-width: 360px;
    }

    body {
        @apply scrollable;
    }

    .scaleOnClick {
        @apply cursor-pointer duration-200 ease-in-out transform active:scale-[.98] transition-transform;
    }

    .scrollable {
        @apply overflow-y-scroll scrollbar scrollbar-thumb-primary scrollbar-track-base;
    }

    ::selection {
        @apply text-selection-foreground bg-selection;
    }

    a {
        @apply transition-all underline scaleOnClick;
    }

    a:hover {
        @apply text-[#158f7a] dark:text-primary decoration-dotted;
    }

    time {
        @apply text-neutral-500 transition-all;
    }
    a:hover time {
        @apply text-[#158f7a] dark:text-primary;
    }

    .marginWrapper {
        @apply m-6 sm:m-10 md:mt-20 md:mx-8 lg:mx-20;
    }
}
